<template>
  <b-card>
    <!-- form -->
    <b-alert
      v-height-fade
      dismissible
      v-model="showDismissibleAlert"
      fade
      class="mb-2"
      variant="danger"
      v-id="showDismissibleAlert == true"
    >
      <div class="alert-body">
        <span>{{ messages }}</span>
      </div>
    </b-alert>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <ValidationProvider
              name="Old Password"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Old Password"
                label-for="account-old-password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <ValidationProvider
              name="New Password"
              rules="required||min:6"
              v-slot="{ errors }"
              vid="confirmation"
            >
              <b-form-group
                label-for="account-new-password"
                label="New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <ValidationProvider
              name="Confirmation Password"
              rules="required|min:6|confirmed:confirmation"
              v-slot="{ errors }"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Retype New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
            >
              Save changes
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Reset
            </b-button> -->
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      messages: "",
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      config: {
        api: "/auth/change-password",
      },
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },

    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push("/login");
    },
    save() {
      let _ = this;

      let data_post = {
        oldPassword: this.passwordValueOld,
        newPassword: this.newPasswordValue,
      };

      axios
        .post(_.config.api, data_post)
        .then((response) => {
          _.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Profile changed successfully. You will be redirected to the login page...",
              icon: "EditIcon",
              variant: "success",
            },
          });

          setTimeout(() => {
            this.logout();
          }, 5000);
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message;
          } else {
            vm.messages = e.response.data.message;
          }
        });
    },
  },
};
</script>
